<template>
  <base-section id="k-d-a-solutions-list-detail" space="0">
    <div v-for="(item, id) in m_arrTechnology" :key="id">
      <k-d-a-solutions-card
        :str-title="item.m_strTitle"
        :str-component="item.m_strComponent"
        :html-description="item.m_htmlDescription"
        :img-solutions="item.m_imgSolutions"
        :num-index="id"
        :is-button-exists="item.m_btnExists"
        :str-button="item.m_strButton"
      />
    </div>
  </base-section>
</template>

<script>
import Projects from '@/mixins/projects';
import TextClass from '@/mixins/text-class';

export default {
  name: 'KDASolutionsListDetail',

  components: {
    KDASolutionsCard: () => import('@/components/KDASolutionsCard')
  },

  mixins: [Projects, TextClass],

  computed: {
    m_arrTechnology() {
      const result = [];

      for (let i = 1; i <= 4; i++) {
        result.push({
          m_strTitle: this.$vuetify.lang.t(`$vuetify.kda.solution.item${i}.title`),
          m_strComponent: 'p',
          m_htmlDescription: this.$vuetify.lang.t(`$vuetify.kda.solution.item${i}.desc`),
          m_imgSolutions: require(`@/assets/photos/solutions-list-0${i}.png`)
        });

        if (i === 4) {
          result[i - 1].m_btnExists = true;
          result[i - 1].m_strButton = 'Book ERP';
        }
      }

      return result;
    }
  }
};
</script>
